export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddressShipping = {
  __typename?: 'AddressShipping';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  location: AddressShippingLocation;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  placeId?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  zip: Scalars['String']['output'];
};

export type AddressShippingLocation = {
  __typename?: 'AddressShippingLocation';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type BlogPost = {
  __typename?: 'BlogPost';
  author: Scalars['String']['output'];
  content: Scalars['String']['output'];
  cover: BlogPostPicture;
  createdAt: Scalars['DateTime']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  intro: Scalars['String']['output'];
  introSeo: Scalars['String']['output'];
  keywordsSeo: Scalars['String']['output'];
  title: Scalars['String']['output'];
  titleSeo: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BlogPostPicture = {
  __typename?: 'BlogPostPicture';
  large: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type Cart = {
  __typename?: 'Cart';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** get cartItems by cart */
  items?: Maybe<Array<CartItem>>;
  promoCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type CartItem = {
  __typename?: 'CartItem';
  cart: Cart;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  product: Product;
  quantity: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactRequest = {
  __typename?: 'ContactRequest';
  city: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreateOrderAddressRequestDto = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type GetProductsFilter = {
  discount: Scalars['Boolean']['input'];
  freeShipping: Scalars['Boolean']['input'];
};

export enum GetProductsSortFactor {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Price = 'PRICE',
  Recommended = 'RECOMMENDED'
}

export type Identification = {
  number: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type LocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type MercadoPagoAddress = {
  __typename?: 'MercadoPagoAddress';
  street_name?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoCard = {
  __typename?: 'MercadoPagoCard';
  bin?: Maybe<Scalars['String']['output']>;
  cardholder?: Maybe<MercadoPagoCardholder>;
  date_created?: Maybe<Scalars['String']['output']>;
  date_last_updated?: Maybe<Scalars['String']['output']>;
  expiration_month?: Maybe<Scalars['Float']['output']>;
  expiration_year?: Maybe<Scalars['Float']['output']>;
  first_six_digits?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_four_digits?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoCardholder = {
  __typename?: 'MercadoPagoCardholder';
  identification?: Maybe<MercadoPagoIdentification>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoIdentification = {
  __typename?: 'MercadoPagoIdentification';
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoPayer = {
  __typename?: 'MercadoPagoPayer';
  address?: Maybe<MercadoPagoAddress>;
  email?: Maybe<Scalars['String']['output']>;
  entity_type?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identification?: Maybe<MercadoPagoIdentification>;
  last_name?: Maybe<Scalars['String']['output']>;
  operator_id?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<MercadoPagoPhone>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoPayment = {
  __typename?: 'MercadoPagoPayment';
  captured?: Maybe<Scalars['Boolean']['output']>;
  card?: Maybe<MercadoPagoCard>;
  currency_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  net_amount?: Maybe<Scalars['Float']['output']>;
  payer?: Maybe<MercadoPagoPayer>;
  payment_method?: Maybe<MercadoPagoPaymentMethod>;
  status?: Maybe<Scalars['String']['output']>;
  status_detail?: Maybe<Scalars['String']['output']>;
  transaction_details?: Maybe<MercadoPagoTransactionDetails>;
};

export type MercadoPagoPaymentMethod = {
  __typename?: 'MercadoPagoPaymentMethod';
  id?: Maybe<Scalars['String']['output']>;
  issuer_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoPhone = {
  __typename?: 'MercadoPagoPhone';
  area_code?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoPreference = {
  __typename?: 'MercadoPagoPreference';
  date_created?: Maybe<Scalars['String']['output']>;
  date_of_expiration?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  purpose?: Maybe<Scalars['String']['output']>;
};

export type MercadoPagoTransactionDetails = {
  __typename?: 'MercadoPagoTransactionDetails';
  acquirer_reference?: Maybe<Scalars['String']['output']>;
  bank_transfer_id?: Maybe<Scalars['String']['output']>;
  digitable_line?: Maybe<Scalars['String']['output']>;
  external_resource_url?: Maybe<Scalars['String']['output']>;
  financial_institution?: Maybe<Scalars['String']['output']>;
  installment_amount?: Maybe<Scalars['Float']['output']>;
  net_received_amount?: Maybe<Scalars['Float']['output']>;
  overpaid_amount?: Maybe<Scalars['Float']['output']>;
  payment_method_reference_id?: Maybe<Scalars['String']['output']>;
  total_paid_amount?: Maybe<Scalars['Float']['output']>;
  transaction_id?: Maybe<Scalars['String']['output']>;
  verification_code?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  approvePaypalOrder: Product;
  createAddressShipping: AddressShipping;
  createCartItem: CartItem;
  createContactRequest: ContactRequest;
  createMercadoPagoPayment: Product;
  createOrder: Order;
  deleteAddressShipping: AddressShipping;
  deleteBlogPostAdmin: BlogPost;
  deleteCart: Cart;
  deleteCartItem: CartItem;
  deleteContactRequestAdmin: ContactRequest;
  deleteProductAdmin: Product;
  deleteProductPictureAdmin: Product;
  deleteProductVideoAdmin: Product;
  deleteProofOfPayment: Order;
  updateAddressShipping: AddressShipping;
  updateBlogPostAdmin: BlogPost;
  updateCartItem: CartItem;
  updateOrderAdmin: Product;
  updateProductAdmin: Product;
  updateUser: User;
  updateUserAdmin: User;
};


export type MutationApprovePaypalOrderArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateAddressShippingArgs = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  placeId?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};


export type MutationCreateCartItemArgs = {
  id: Scalars['String']['input'];
  product: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
};


export type MutationCreateContactRequestArgs = {
  city: Scalars['String']['input'];
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  recaptchaToken: Scalars['String']['input'];
};


export type MutationCreateMercadoPagoPaymentArgs = {
  id: Scalars['String']['input'];
  issuer_id: Scalars['String']['input'];
  payer: Payer;
  payment_method_id: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCreateOrderArgs = {
  cart: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  paymentType: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  shippingAddress?: InputMaybe<CreateOrderAddressRequestDto>;
  shippingType: Scalars['String']['input'];
  store?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteAddressShippingArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBlogPostAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCartArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCartItemArgs = {
  id: Scalars['String']['input'];
  product: Scalars['String']['input'];
};


export type MutationDeleteContactRequestAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProductAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProductPictureAdminArgs = {
  id: Scalars['String']['input'];
  index: Scalars['Float']['input'];
};


export type MutationDeleteProductVideoAdminArgs = {
  id: Scalars['String']['input'];
  index: Scalars['Float']['input'];
};


export type MutationDeleteProofOfPaymentArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateAddressShippingArgs = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  placeId?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};


export type MutationUpdateBlogPostAdminArgs = {
  author: Scalars['String']['input'];
  content: Scalars['String']['input'];
  hidden: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  intro: Scalars['String']['input'];
  introSeo: Scalars['String']['input'];
  keywordsSeo: Scalars['String']['input'];
  title: Scalars['String']['input'];
  titleSeo: Scalars['String']['input'];
};


export type MutationUpdateCartItemArgs = {
  id: Scalars['String']['input'];
  product: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
};


export type MutationUpdateOrderAdminArgs = {
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
  tokenOrderPickUp?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateProductAdminArgs = {
  category: Scalars['String']['input'];
  description: Scalars['String']['input'];
  descriptionSeo: Scalars['String']['input'];
  discountPercent?: InputMaybe<Scalars['Float']['input']>;
  hidden: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  keywordsSeo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameSeo: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  shippingLocal?: InputMaybe<Scalars['Float']['input']>;
  shippingNational?: InputMaybe<Scalars['Float']['input']>;
  slug: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdateUserAdminArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type OpenNodeCharge = {
  __typename?: 'OpenNodeCharge';
  address?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  auto_settle?: Maybe<Scalars['Boolean']['output']>;
  chain_invoice?: Maybe<OpenNodeOnChainInvoice>;
  created_at?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  fiat_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  metadata?: Maybe<OpenNodeChargeMetadata>;
  missing_amt?: Maybe<Scalars['Float']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  source_fiat_value?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactions?: Maybe<Array<OpenNodeChargeTransaction>>;
};

export type OpenNodeChargeMetadata = {
  __typename?: 'OpenNodeChargeMetadata';
  email?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OpenNodeChargeTransaction = {
  __typename?: 'OpenNodeChargeTransaction';
  address?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  settled_at?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tx?: Maybe<Scalars['String']['output']>;
};

export type OpenNodeOnChainInvoice = {
  __typename?: 'OpenNodeOnChainInvoice';
  address?: Maybe<Scalars['String']['output']>;
  settled_at?: Maybe<Scalars['Float']['output']>;
  tx?: Maybe<Scalars['String']['output']>;
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime']['output'];
  discount: Scalars['Float']['output'];
  email: Scalars['String']['output'];
  getOrderEvents: Array<OrderEvent>;
  getOrderEventsAdmin: Array<OrderEvent>;
  guestToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<OrderItem>;
  mercadoPagoPayment?: Maybe<MercadoPagoPayment>;
  mercadoPagoPreference?: Maybe<MercadoPagoPreference>;
  name: Scalars['String']['output'];
  openNodeCharge?: Maybe<OpenNodeCharge>;
  paymentType: PaymentType;
  paypalOrder?: Maybe<PaypalOrder>;
  phone: Scalars['String']['output'];
  proofOfPayment?: Maybe<Scalars['String']['output']>;
  shipping: Scalars['Float']['output'];
  shippingAddress?: Maybe<OrderAddress>;
  shippingType: ShippingType;
  slug: Scalars['String']['output'];
  status: OrderStatus;
  store?: Maybe<Store>;
  subtotal: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  location: OrderAddressLocation;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type OrderAddressLocation = {
  __typename?: 'OrderAddressLocation';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  product: OrderItemProduct;
  quantity: Scalars['Float']['output'];
};

export type OrderItemProduct = {
  __typename?: 'OrderItemProduct';
  discountPercent?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  order?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Payer = {
  email: Scalars['String']['input'];
  identification: Identification;
};

export type PaymentType = {
  __typename?: 'PaymentType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionLong?: Maybe<Scalars['String']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  paymentAccount?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaypalName = {
  __typename?: 'PaypalName';
  given_name?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
};

export type PaypalOrder = {
  __typename?: 'PaypalOrder';
  id?: Maybe<Scalars['ID']['output']>;
  intent?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<PaypalPayer>;
  payment_source?: Maybe<PaypalPaymentSource>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PaypalPayer = {
  __typename?: 'PaypalPayer';
  email_address?: Maybe<Scalars['String']['output']>;
  name?: Maybe<PaypalName>;
  payer_id?: Maybe<Scalars['String']['output']>;
};

export type PaypalPaymentSource = {
  __typename?: 'PaypalPaymentSource';
  paypal?: Maybe<PaypalWallet>;
};

export type PaypalWallet = {
  __typename?: 'PaypalWallet';
  account_id?: Maybe<Scalars['String']['output']>;
  account_status?: Maybe<Scalars['String']['output']>;
  email_address?: Maybe<Scalars['String']['output']>;
  name?: Maybe<PaypalName>;
};

export type Product = {
  __typename?: 'Product';
  category: ProductCategory;
  /** Computed price - from aggregation */
  computedPrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  descriptionSeo: Scalars['String']['output'];
  discountPercent?: Maybe<Scalars['Float']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  keywordsSeo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nameSeo: Scalars['String']['output'];
  pictures: Array<ProductPicture>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  /** Html highlight for search - from aggregation */
  searchHighlights?: Maybe<Scalars['String']['output']>;
  /** Score for search - from aggregation */
  searchScore?: Maybe<Scalars['Float']['output']>;
  shippingLocal?: Maybe<Scalars['Float']['output']>;
  shippingNational?: Maybe<Scalars['Float']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  videos: Array<ProductVideo>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductPicture = {
  __typename?: 'ProductPicture';
  large: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type ProductVideo = {
  __typename?: 'ProductVideo';
  original: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  getAddressesShipping: Array<AddressShipping>;
  getBlogPost: BlogPost;
  getBlogPostAdmin: BlogPost;
  getBlogPosts: Array<BlogPost>;
  getBlogPostsAdmin: Array<BlogPost>;
  getCart?: Maybe<Cart>;
  getCartById: Cart;
  getContactRequestAdmin: ContactRequest;
  getContactRequestsAdmin: Array<ContactRequest>;
  getOrder: Order;
  getOrderAdmin: Order;
  getOrderEvents: Array<OrderEvent>;
  getOrderEventsAdmin: Array<OrderEvent>;
  getOrderStatuses: Array<OrderStatus>;
  getOrderStatusesAdmin: Array<OrderStatus>;
  getOrders: Array<Order>;
  getOrdersAdmin: Array<Order>;
  getPaymentTypes: Array<PaymentType>;
  getProduct: Product;
  getProductAdmin: Product;
  getProductCategories: Array<ProductCategory>;
  getProductHistory: Array<Product>;
  getProductHistoryCart: Array<Product>;
  getProducts: Array<Product>;
  getProductsAdmin: Array<Product>;
  getProductsAlike: Array<Product>;
  getProductsRelated: Array<Product>;
  getProductsRelatedByCart: Array<Product>;
  getShippingTypes: Array<ShippingType>;
  getStores: Array<Store>;
  getUser?: Maybe<User>;
  getUserAdmin: User;
  getUserRoles: Array<UserRole>;
  getUsersAdmin: Array<User>;
  searchProducts: Array<Product>;
};


export type QueryGetAddressesShippingArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetBlogPostArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBlogPostAdminArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetBlogPostsArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetBlogPostsAdminArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetCartArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCartByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetContactRequestAdminArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetContactRequestsAdminArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetOrderArgs = {
  guestToken?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type QueryGetOrderAdminArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrderEventsArgs = {
  guestToken?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetOrderEventsAdminArgs = {
  id: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetOrdersArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetOrdersAdminArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetProductArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetProductAdminArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetProductHistoryArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetProductHistoryCartArgs = {
  id: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetProductsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  filter: GetProductsFilter;
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
  sortFactor: GetProductsSortFactor;
  sortOrder: Scalars['Float']['input'];
};


export type QueryGetProductsAdminArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetProductsAlikeArgs = {
  id: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetProductsRelatedArgs = {
  id: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetProductsRelatedByCartArgs = {
  id: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QueryGetUserAdminArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetUsersAdminArgs = {
  limit: Scalars['Float']['input'];
  skip: Scalars['Float']['input'];
};


export type QuerySearchProductsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  filter: GetProductsFilter;
  limit: Scalars['Float']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Float']['input'];
  sortFactor: GetProductsSortFactor;
  sortOrder: Scalars['Float']['input'];
};

export type ShippingType = {
  __typename?: 'ShippingType';
  createdAt: Scalars['DateTime']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Store = {
  __typename?: 'Store';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  location: StoreLocation;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  placeId?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  zip: Scalars['String']['output'];
};

export type StoreLocation = {
  __typename?: 'StoreLocation';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  googleId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role: UserRole;
  stripeId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserRole = {
  __typename?: 'UserRole';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};
